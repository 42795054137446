import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import React from "react";
import Subscriptionlanding from "./../@narative/gatsby-theme-novela/components/Subscriptionlanding";

const headingText = {
  main: "Better get me some of that leverage.",
  sub: "Daily views and news to help you build a better tech future"
} 

function Landing() {
  return (
    <Layout>
      <SEO />
      <Section>
        <div style={{ marginTop: "100px" }}>
          <Headings.h1>{headingText.main}</Headings.h1>
        </div>
        <div style={{ marginTop: "50px" }}>
          <Headings.h3>{headingText.sub}</Headings.h3>
        </div>
        <div style={{ marginTop: "50px" }}>
        <Subscriptionlanding />
        </div>
      
      </Section>
    </Layout>
  );
}

export default Landing;
